html, body, .theme-base, .theme-accent, #vue-container {
	height: 100%;
	font-size: 100%;
}

* {
	padding: 0;
	margin: 0;
	font-family: 'Hind', sans-serif;
	font-weight: 300;
	color: var(--theme-foreground);
}

li {
	list-style-position: inside;
	list-style-type: none;
}

h2 {
	font-size: 2rem;
	font-family: 'Hind', sans-serif;
	color: var(--theme-foreground-muted);
}

a {
	text-decoration: none;
}

button:focus {
	outline: none;
}

button::-moz-focus-inner {
	border: 0;
}

/*Buttons*/
input[type="submit"], button {
	display: block;
	padding: 5px;
	padding-left: 30px;
	padding-right: 30px;
	border: none;
	border-radius: 5px;
	background-color: var(--theme-accent);
}

input[type="submit"], input[type="submit"] *, button.submit, button.submit * {
	color: var(--theme-foreground-against-accent);
}

button, button * {
	cursor: pointer;
}

input[type="submit"], input[type="submit"] *, button, button * {
	font-weight: 600;
}

button:not(.submit) {
	background-color: var(--theme-background);
	border: 1px solid var(--theme-border);
	margin-top: 0;
}

button.danger {
	background-color: var(--theme-bad);
	color: var(--theme-foreground-against-bad);
	border: 0;
}

button.small {
	font-size: 0.8125rem;
	padding: 0;
	padding-left: 15px;
	padding-right: 15px;
	margin-right: 10px;
}

input[type="submit"]:disabled, button:disabled {
	background-color: var(--theme-foreground-muted);
}

/*Forms*/
form .field {
	width: 70%;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 10px;
	background-color: var(--theme-background-muted);
	margin-bottom: 25px;
}

form label {
	display: block;
	font-weight: 600;
	margin-bottom: 2px;
}

form input, form select {
	width: 100%;
	padding: 4px;
	padding-left: 8px;
	padding-right: 8px;
	margin-bottom: 5px;
	border: 1px solid var(--theme-border);
	border-radius: 3px;
	font-size: 0.875rem;
	box-sizing: border-box;
	background-color: var(--theme-background);
}

select {
	border: 1px solid var(--theme-border-muted);
	border-radius: 3px;
}

select, select option {
	background-color: var(--theme-background);
}

/*Generic form help*/
.explanation {
	text-align: justify;
	margin-bottom: 20px;
	line-height: 1.25;
}

.tip {
	font-size: 0.8125rem;
	color: var(--theme-foreground-muted);
}

.tip.error {
	color: var(--theme-bad);
	font-weight: 400;
}

/*Mount points, theme preferences*/
form table {
	width: 100%;
	border-spacing: 10px 5px;
	margin-top: -5px;
	margin-left: -10px;
	margin-right: -10px;
}

form table input, form table select {
	width: 100%;
	box-sizing: border-box;
}

form td input, form td select {
	margin: 0;
}

form td:first-child {
	width: 60%;
}

form td:nth-child(2) {
	width: 40%;
}

form th {
	text-align: left;
	color: var(--theme-foreground-muted);
	font-size: 0.8125rem;
}

form td i {
	cursor: pointer;
	position: relative;
	top: 2px;
}

.help {
	position: relative;
	top: 100px;
	margin-left: auto;
	margin-right: auto;
	max-width: 350px;
	text-align:center;
	font-size: 1.375rem;
}

.help, .help i {
	color: var(--theme-foreground-muted);
}

/*Material icons*/
.material-icons.md-16 { font-size: 16px; }
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

/*Prevent text selection*/
.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
